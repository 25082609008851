@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;900&display=swap);
:root {
  --background-light: radial-gradient(
    circle at 10% 20%,
    rgba(216, 241, 230, 0.46) 0.1%,
    rgba(233, 226, 226, 0.28) 90.1%
  );
  --background-dark: linear-gradient(
    109.6deg,
    #242d39 11.2%,
    #10253c 51.2%,
    #000 98.6%
  );
  --text-black: #000;
  --text-white: #fff;
  --card-shadow: -1px 1px 58px -14px #9e0575;
  --purple-gradient: linear-gradient(90.4deg, #f834f6 0.6%, #9826fc 90%);
  --orange-gradient: radial-gradient(
    circle at 10% 20%,
    #ffa872 0,
    #fe6aa4 100.2%
  );
}
.back-to-top-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #f834f6;
  border: none;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  font-size: 24px;
  cursor: pointer;
  transition: background-color 0.3s;
}
.logoutbtn,
.menubtn,
.modebtn {
  position: absolute;
  top: 0;
  font-size: 22px;
  font-weight: 500;
}
.modebtn {
  left: 40%;
}
.back-to-top-button:hover {
  background-color: #9826fc;
}
.light-mode {
  background: var(--background-light);
  color: var(--text-black) !important;
}
.dark-mode table
{
  color: white!important;
  background-color: inherit!important;
}
#topsize
{
  margin-top: 100px;
}

@media only screen and (max-width: 992px) {
  
  #light-mode .offcanvas-body,
  #light-mode .offcanvas-header {
    background: var(--background-light);
  }
  #dark-mode .offcanvas {
    background: var(--background-dark);
    color: var(--text-white);
  }
  .modebtn {
    display: none;
  }
  .logoutbtn,
  .menubtn,
  .modebtn {
    font-size: 18px;
  }
}
#light-mode .offcanvasofsidebar {
  background: #eff8f3;
}
#dark-mode .navbar,
#dark-mode .offcanvasofsidebar,
.dark-mode {
  background: var(--background-dark);
  color: var(--text-white);
}
.card-price,
.contact-form,
.features-card,
.pricing-cards,
.register-form,
.subscribe-btn {
  box-shadow: var(--card-shadow);
}
.card-ex,
.card-price {
  border-radius: 10px;
  width: fit-content;
  padding: 10px;
}
.icon-btn,
.tick {
  margin-right: 5px;
}
.logo,
img {
  height: auto;
}
body {
  font-family: Poppins, sans-serif !important;
  font-weight: 400;
  overflow-x: hidden;
  min-height: 100vh;
}
.dark-mode {
  color: var(--text-white) !important;
}
.features-card,
.pricing-cards {
  color: var(--text-white);
  transition: transform 0.3s;
  cursor: pointer;
  text-align: center;
  margin-bottom: 20px;
}
section {
  min-height: 80vh;
}

.features-card {
  background: var(--purple-gradient);
  font-weight: 500;
}
.loginbtn {
  margin-left: 10px;
}
.login-btn,
.pricing-cards {
  background: var(--orange-gradient);
  font-weight: 500;
}
.contact-form,
.login-btn,
.register-form,
.register-form input,
select {
  color: var(--text-black) !important;
  font-weight: 600;
}
#dark-mode .nav-link,
#dark-mode .navbar-brand,
#dark-mode .offcanvas-header,
.card-price {
  color: var(--text-white);
}
.card-price {
  background: var(--purple-gradient);
}
.card-ex {
  background-color: #0ff;
  color: var(--text-black);
}
.card:hover {
  transform: scale(1.05);
}
.tick {
  width: 40px;
  border-radius: 50%;
}
.benefits {
  text-align: left;
}
.subscribe-btn {
  background: var(--purple-gradient);
  color: var(--text-white) !important;
  border-radius: 10px;
}
.form-text {
  color: grey !important;
}
.form-control,
input,
option,
select,
textarea {
  background: 0 0 !important;
}
img {
  width: 100%;
}
.menubtn {
  left: 0;
}
.logoutbtn {
  right: 0;
}
.icon-btn {
  width: 40px !important;
  border-radius: 5px;
}
.logo {
  width: 40px;
}
.nav-link,
.navbar-brand {
  text-align: center;
  font-weight: 500 !important;
}
.menu_active {
  display: block;
  padding: 0.5rem 1rem;
  font-weight: 700;
  text-decoration: none;
  background-color: #7fffd4 !important;
  color: var(--text-black) !important;
  border-radius: 10px !important;
}
.contact-form,
.register-form {
  padding: 20px;
  margin-bottom: 20px !important;
}
.nav-link {
  color: var(--text-black);
}
.contact-form {
  background: var(--orange-gradient);
  border-radius: 10px;
}
.register-form {
  background: var(--purple-gradient);
  border-radius: 10px;
}
.form-text,
.register-form ::placeholder {
  color: #d3d3d3 !important;
}
.outer {
  display: table;
  height: 100%;
  width: 100%;
}
.middle {
  display: table-cell;
  vertical-align: middle;
}
.inner {
  margin-left: auto;
  margin-right: auto;
  height: auto;
  width: 100%;
}
#animateimg {
  animation: 2s ease-in-out infinite alternate-reverse both up-down;
  border-radius: 10px;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
  transition: transform 0.5s;
}
@-webkit-keyframes up-down {
  0% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(-10px);
  }
}
@keyframes up-down {
  0% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(-10px);
  }
}
#animateimg:hover {
  position: relative;
  transform-style: preserve-3d;
  animation: 30s linear infinite animate;
  cursor: pointer;
}
@keyframes animate {
  0% {
    transform: perspective(1000px) rotateY(0);
  }
  100% {
    transform: perspective(1000px) rotateY(-370deg);
  }
}
